import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function DoNotHavePermission({ history }) {
  const { t } = useTranslation('common')
  return (
    <div className="error-404">
      <div className="error-404__text">
        <p style={{ marginTop: '10%', fontSize: '30px' }}>{t('permission_title')}</p>
      </div>

      <div className="error-404__btn">
        <a
          className="btn btn-large"
          href={'/dashboard'}
          // onClick={history.goBack}
        >
          {t('back')}
        </a>
      </div>
    </div>
  )
}

export default withRouter(DoNotHavePermission)
