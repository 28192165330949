import ApiService from './request-services'
import { FileServiceApi } from './request-services'


export const createVideo = async data => {
  try {
    const body = data
    const results = await ApiService.post('/api/videos/create', body)
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideos = async () => {
  try {
    const results = await ApiService.get('/api/videos')
    return results.data
  } catch (e) {
    throw e
  }
}

export const updateVideo = async (id, data) => {
  // console.log('updateVideo: ', id, data)
  try {
    const results = await ApiService.put('/api/videos/update/' + id, data)
    // console.log('results', results.data)
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteVideo = async id => {
  try {
    // console.log("deleteVideo:id----- ", id)
    const results = await ApiService.delete('/api/videos/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}
export const getVideosPerPage = async (page, size, categoryId) => {
  try {
    const results = await ApiService.get(`/api/videos/list`, {
      params: { page, size, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

// GET /video

export const getVideosPerKeyword = async (page, size, keyword, categoryId) => {
  try {
    const results = await ApiService.get(`/api/videos/list`, {
      params: { page, size, keyword, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const uploadImageVideo = async (access_token, data, dir) => {
  try {
    const results = await ApiService.post(`/api/videos/upload-image?directory=${dir}`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        Authorization: `Bearer ${access_token}`
      }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteImageVideo = async (path) => {
  try {
    const results = await ApiService.delete(`/api/videos/image?path=${path}`)
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteImageAndVideoFile = async (dir) => {
  // console.log("deleteImageAndVideoFile", video_file_id)
  try {
    const results = await ApiService.delete(`/api/videos/image-and-video-file?dir=${dir}`)
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideosActive = async () => {
  try {
    const results = await ApiService.get('/api/videos/active')
    return results.data
  } catch (e) {
    throw e
  }
}

export const uploadVideo = async (access_token, data) => {
  try {
    const results = await ApiService.post('/api/videos/upload', data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        Authorization: `Bearer ${access_token}`
      }
    })
    return results.data
  } catch (e) {
    throw e
  }
}


export const getStatisticPerDay = async (start_date, end_date) => {
  try {
    const results = await ApiService.get('/api/videos/statistics', {
      params: { start_date, end_date }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideoInfomation = async (video_uuid) => {
  try {
    const results = await ApiService.get(`/api/videos/infomation`, {
      params: { uuid: video_uuid }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideoComment = async (page, size, video_id, sort) => {
  try {
    const results = await ApiService.get(`/api/videos-comments`, {
      params: { page, size, video_id, sort }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideosPerPageAndViewSort = async (page, size, sort) => {
  try {
    const results = await ApiService.get(`/api/videos/list`, {
      params: { page, size, sort }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleCommentInVideo = async (payload) => {
  try {
    const results = await ApiService.post(`/api/videos-comments/create`, payload)
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleLikeVideo = async (id) => {
  try {
    const results = await ApiService.put('/api/videos/like-video/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleDislikeVideo = async (id) => {
  try {
    const results = await ApiService.put('/api/videos/dislike-video/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}

export const downloadVideo = async (id) => {
  try {
    const results = await FileServiceApi.get('/api/videos/download/' + id);
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleLikeCommentVideo = async (id) => {
  try {
    const results = await ApiService.put('/api/videos-comments/like-comment/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleDislikeVideoComment = async (id) => {
  try {
    const results = await ApiService.put('/api/videos-comments/dislike-comment/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}

export const getVideoHistory = async (page, size, user_id, categoryId) => {
  try {
    const results = await ApiService.get(`/api/video-histories`, {
      params: { page, size, user_id, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteVideoComment = async (id) => {
  try {
    const results = await ApiService.delete(`/api/videos-comments/${id}`)
    return results.data
  } catch (e) {
    throw e
  }
}