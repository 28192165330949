import { Layout, Menu, Drawer } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import windowSize from 'react-window-size'
import ApiService from '../../api-services/request-services'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_en } from '../../constants_en'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_vi } from '../../constants_vi'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { getUserSession, removeUserSession } from '../../utils/user-func'
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons'
import about_us from '../../assets/images/new-image/sidebar-about_us.png'
import game from '../../assets/images/new-image/sidebar-game.png'
import home from '../../assets/images/new-image/sidebar-home.png'
import learning from '../../assets/images/new-image/sidebar-learning.png'
import life_tip from '../../assets/images/new-image/sidebar-life_tip.png'
import pingpong from '../../assets/images/new-image/sidebar-pingpong.png'
import podcast from '../../assets/images/new-image/sidebar-podcast.png'
import video from '../../assets/images/new-image/sidebar-video.png'
import icon_logout from '../../assets/images/new-image/icon-logout-1.svg'
import icon_18_plus from '../../assets/images/new-image/icon-18.1.png'
const { Sider } = Layout

function SideBar(props) {
  const patchCurrent = props.location.pathname

  const user = getUserSession()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedMobile, setCollapsedMoblie] = useState(true)
  const [current, setCurrent] = useState(patchCurrent === '/' || patchCurrent === '' ? '/dashboard' : patchCurrent)
  const { t, i18n } = useTranslation('common')
  const SIDE_BAR_MOBILE_LINK = i18n.language === 'vi' ? SIDE_BAR_MOBILE_LINK_vi : SIDE_BAR_MOBILE_LINK_en
  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem('language', lang)
    ApiService.defaults.headers.common['Accept-Language'] = lang
  }

  useEffect(() => {
    if (props.windowWidth < 1200) {
      props.stateWidth(collapsed)
    }
    if (props.windowWidth < 1201) {
      setCollapsed(true)
    }
  }, [props.windowWidth, collapsed])

  const extractPath = url => {
    const parts = url.split('/') // Tách URL thành các phần dựa trên dấu '/'
    if (parts.length >= 2) {
      // Kiểm tra xem có ít nhất hai phần tử trong mảng hay không
      return '/' + parts[1] // Trả về phần tử thứ hai cộng thêm dấu '/'
    } else {
      return null // Trả về null nếu không có đủ phần tử
    }
  }

  useEffect(() => {
    if (patchCurrent) {
      if (current !== patchCurrent) {
        setCurrent(extractPath(patchCurrent))
      }
    }
  }, [patchCurrent, current])

  const handleClickBacdrop = () => {
    if (props.windowWidth < 1201) {
      setCollapsedMoblie(true)
    }
  }

  const handleClickLogOut = () => {
    removeUserSession()
    props.dispatch(isUserLoggedIn(false))
    setCollapsed(true)
    setCollapsedMoblie(true)
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    setCollapsedMoblie(!collapsedMobile)
    if (props.windowWidth > 1200) {
      setCollapsedMoblie(true)
    }
  }

  function handleClick(e) {
    setCurrent(e.key)
    props.onCloseDrawer()
  }

  // console.log("SideBar--------------", props, props.isLoggedIn)
  return (
    <div>
      <React.Fragment>
        {/* <Layout style={{ minHeight: '100vh' }} className="sidebar-customer-mobile"> */}
        <Sider
          collapsible={props.isToggled}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
          width={234}
          collapsedWidth={0}
          theme="dark"
        // breakpoint="lg"
        >
          <Menu
            defaultSelectedKeys={['0']}
            mode="inline"
            theme="dark"
            className="sidebar-customer-content"
            id="sb-content"
            onSelect={handleClick}
            selectedKeys={[current]}
          >
            {/* <Link to={'/dashboard'}>
                  {(props.windowWidth > 1200 && collapsed) || (props.windowWidth < 1201 && collapsedMobile) ? (
                    <img src={img_goong_logo_sb} alt="goong-logo" className="icon-goong-sb-am" />
                  ) : (
                    <img src={img_goong_logo_2x} alt="goong-logo" className="icon-goong-2x" />
                  )}
                </Link> */}
            {/* {(collapsed === false || collapsedMobile === false) && <Divider />} */}
            <Menu.Item key="/dashboard">
              <Link to={'/dashboard'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={home} alt="home" />
                <span className="sidebar-label">{t('home')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/video">
              <Link to={'/video'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={video} alt="about_us" />
                <span className="sidebar-label">{t('video')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/podcast">
              <Link to={'/podcast'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={podcast} alt="game" />
                <span className="sidebar-label">{t('podcast')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/life-tips">
              <Link to={'/life-tips'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={life_tip} alt="life_tip" />
                <span className="sidebar-label">{t('life_tip')}</span> &nbsp;<img style={{width: '18px'}} src={icon_18_plus} alt="icon_18_plus" />
              </Link>
            </Menu.Item>
            <Menu.Item key="/learning">
              <Link to={'/learning'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={learning} alt="learning" />
                <span className="sidebar-label">{t('learning')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/game">
              <Link to={'/game'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={game} alt="pingpong" />
                <span className="sidebar-label">{t('game')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/pingpong">
              <Link to={'/pingpong'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={pingpong} alt="podcast" />
                <span className="sidebar-label">{t('pingpong')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/about-us">
              <Link to={'/about-us'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img className="sidebar-icon" src={about_us} alt="video" />
                <span className="sidebar-label">{t('about_us')}</span>
              </Link>
            </Menu.Item>

            {props.windowWidth < 992 && (
              <>
                {!user?.user_id ? (
                  <Menu.Item key="/login">
                    <Link to={'/login'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                      <LoginOutlined />
                      <span className="sidebar-label">{t('signin')}</span>
                    </Link>
                  </Menu.Item>
                ) : (
                  <Menu.Item key="">
                    <Link
                      to={''}
                      className="menu-mobile__link"
                      onClick={e => {
                        window.localStorage.removeItem('likeitis_user_client')
                        window.location.reload()
                      }}
                      style={{ color: '#FF5757' }}
                    >
                      <img className="" src={icon_logout} alt="icon_logout" />
                      <span className="sidebar-label" style={{ color: '#FF5757', marginLeft: '10px' }} >{t('log_out')}</span>
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </Menu>
        </Sider>
        {/* </Layout> */}
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn
  }
}

export default windowSize(withRouter(connect(mapStateToProps)(SideBar)))
