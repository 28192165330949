import { BackTop, Col, Drawer, Layout, Row, Spin } from 'antd'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { NotificationContainer } from 'react-notifications'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom'
import windowSize from 'react-window-size'
import AsyncNotFound from '../../components/NotFound'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { appRoutes } from '../../routes/index'
import i18next from '../../translations/i18n_config'
import { getUserSession, isHavePermission } from '../../utils/user-func'
import DoNotHavePermission from '../DoNotHavePermission'
import Maintain from '../Maintain'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import SideBarAdmin from './SideBarAdmin'
// import logo_loading from '../../assets/images/new-image/logo-loading.svg'
import logo_loading_icon from '../../assets/images/new-image/logo-header.png'
// import icon_call from '../../assets/images/new-image/icon-call-1.svg'
import SideBarProfile from './SideBarProfile'
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import icon_menu_active from '../../assets/images/new-image/icon-menu-active.svg'
import logo_default from '../../assets/images/new-image/logo-default.svg'

import icon_btt from '../../assets/images/new-image/icon-btt.svg'
import loading from '../../assets/images/new-image/Loading.json'
import Lottie from 'react-lottie';
const { Content } = Layout

function App(props) {
  const history = useHistory()
  const user = getUserSession()

  const [isAdminLayout, setIsAdminLayout] = useState(false)
  const [roles, setRoles] = useState(['customer'])
  const [classMainAdmin, setClassMainAdmin] = useState('admin-wrapper')
  const [classMainCustomer, setClassMainCustomer] = useState('goong-wrapper')
  const [classFooterCustomer, setClassFooterCustomer] = useState('goong-footer')
  const [isMaintain, setIsMaintain] = useState(false)
  const [isToggled, setToggled] = useState(false)
  const [isNotFound, setIsNotFound] = useState(true)
  const [isShowBtt, setIsShowBtt] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isLoadingContainer, setIsLoadingContainer] = useState(false)

  const patchCurrent = props.location.pathname

  // const notFound =   {
  //   path: '',
  //   name: '404',
  //   component: AsyncNotFound,
  //   exact: false,
  //   layout: 'customer'
  // };

  useEffect(() => {
    if (user) {
      document.body.classList.add('goong-body')
    } else {
      document.body.classList.remove('goong-body')
    }
    window.onload = () => {
      setIsLoadingContainer(true)
      document.getElementById('loader-overlay').style.display = 'none'

      // // Hiển thị nội dung của trang web
      document.getElementById('content').style.display = 'block'
    }

  }, [props.location])

  useEffect(() => {
    if (typeof props.isLoggedIn === 'undefined') {
      props.dispatch(isUserLoggedIn(!!getUserSession()))
    }
    const _roles = getUserSession() && getUserSession()['role'] ? getUserSession()['role'] : ['customer']
    setRoles(_roles)
    let pathname = props.location.pathname
    if (pathname === '/maintain') {
      setIsMaintain(true)
      return
    }
    for (let i = 0; i < appRoutes.length; i++) {
      const route = appRoutes[i]
      if (pathname === route.path) {
        setIsNotFound(false)
      } else if (pathname.includes(route.path) && pathname.includes(route.path + '/')) {
        setIsNotFound(false)
      }
      if (route.path === pathname) {
        setIsNotFound(false)
        setIsAdminLayout(route.layout === 'admin')
        break
      } else {
        setIsAdminLayout(false)
      }
    }
  }, [props, isAdminLayout])

  useEffect(() => {
    if (props.windowWidth > 1200 || props.windowWidth < 651) {
      setClassMainCustomer('goong-wrapper')
      setClassFooterCustomer('goong-footer')
    }
  }, [props.windowWidth])

  useEffect(() => {
    function handleScroll() {
      const currentPosition = window.scrollY
      setScrollPosition(currentPosition)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // debugger
    if (scrollPosition > 400) {
      setIsShowBtt(true)
    } else {
      setIsShowBtt(false)
    }
  }, [scrollPosition])

  const onStateWidth = stateW => {
    if (stateW || 'mobile') {
      setClassMainAdmin('admin-wrapper-setWidth')
    }
    if (stateW === false && props.windowWidth > 900) {
      setClassMainAdmin('admin-wrapper-setWidth')
    }
  }

  const onWidthCustomer = st => {
    if (st === false && props.windowWidth > 650) {
      setClassMainCustomer('goong-wrapper-setWidth')
      setClassFooterCustomer('goong-footer-setWidth')
    }
    if (st && props.windowWidth > 650) {
      setClassMainCustomer('goong-wrapper')
      setClassFooterCustomer('goong-footer')
    }
  }

  const toggleTrueFalse = () => setToggled(!isToggled)
  const onClose = () => {
    setToggled(false)
  }

  const goToProfile = () => {
    onClose()
    history.push('/profile')
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const renderLoading = () => {
    return (
      <div className='container-loading'>
        <Spin size="large" />
      </div>
    )
  }



  return (
    <React.Fragment>
      <I18nextProvider i18n={i18next}>
        {isMaintain ? (
          <Maintain />
        ) : (
          <>
            <div id="loader-overlay">
              <div id="loader">
                {/* <img src={logo_loading_icon} alt="logo_loading" /> */}
                <Lottie options={defaultOptions}
                  isStopped={isLoadingContainer} />
              </div>
            </div>
            <Layout id="content">
              <Layout className={isAdminLayout ? classMainAdmin : classMainCustomer} id={'layout-set'}>
                <Layout>
                  <Drawer
                    placement="left"
                    onClose={onClose}
                    closable={false}
                    open={isToggled}
                    className="hideOnDesktop"
                    width={234}
                    bodyStyle={{ backgroundColor: '#18224f ', padding: '0', overflow: 'hidden' }}
                  >
                    <div className="menu-info-mobile">
                      <img
                        className="menu-info-mobile_icon-drawer"
                        src={icon_menu_active}
                        alt="icon_menu_active"
                        onClick={() => toggleTrueFalse()}
                      />
                      {user && (
                        <Row align="center" justify="start" wrap={false}>
                          <Col onClick={e => goToProfile()}>
                            <img className="menu-info-mobile_img-avt" src={logo_default} alt="logo_default" />
                          </Col>
                          <Col onClick={e => goToProfile()}>
                            <Row>
                              <Col className="menu-info-mobile_mail">{user?.email}</Col>
                              <Col className="menu-info-mobile_name">
                                {user?.user_profile?.first_name}&nbsp;{user?.user_profile?.last_name}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <SideBar stateWidth={onWidthCustomer} onCloseDrawer={onClose} className="hideOnDesktop" />
                  </Drawer>
                  {!isAdminLayout && <Header isToggled={isToggled} toggleTrueFalse={() => toggleTrueFalse()} />}
                  {isAdminLayout ? (
                    <SideBarAdmin stateWidth={onStateWidth} />
                  ) : patchCurrent == '/profile' ? (
                    <SideBarProfile stateWidth={onWidthCustomer} />
                  ) : (
                    <div className="hideOnMobile">
                      <div style={{ position: 'sticky', top: '100px' }}>
                        <SideBar stateWidth={onWidthCustomer} isToggled={isToggled} onCloseDrawer={onClose} />
                      </div>
                    </div>
                  )}
                  <Content>
                    <div
                      className={
                        props.windowWidth >= 992
                          ? isAdminLayout
                            ? 'layout-admin-container'
                            : 'layout-custom layout-background-color'
                          : 'layout-custom-mobile'
                      }
                    >
                      <Switch>
                        {isNotFound ? (
                          <AsyncNotFound />
                        ) : (
                          <Suspense fallback={renderLoading()}>
                            {appRoutes.map(({ name, exact, path, component: Component, isProtected, onlyAllow }) => {
                              return (
                                <Route
                                  key={name}
                                  exact={exact}
                                  path={path}
                                  // component={Component}
                                  render={routeProps => {
                                    if (isProtected) {
                                      if (!props.isLoggedIn) {
                                        return <Redirect to={{ pathname: '/dashboard' }} />
                                        // return <Redirect to={{ pathname: path }} />
                                      }

                                      if (isHavePermission(roles, onlyAllow)) {
                                        return <Component {...routeProps} />
                                      } else return <DoNotHavePermission />
                                    } else {
                                      if (props.isLoggedIn && name === 'Login') {
                                        return <Redirect to={{ pathname: '/dashboard' }} />

                                        // return <Redirect to={{ pathname: path }} />
                                      }

                                      return <Component {...routeProps} />
                                    }
                                  }}
                                />
                              )
                            })}
                          </Suspense>
                        )}
                      </Switch>
                    </div>
                    {!isAdminLayout &&
                      (props.isLoggedIn || props.isLoggedIn === false ? (
                        <div className="footer-container">
                          <Footer />
                        </div>
                      ) : (
                        ''
                      ))}
                  </Content>
                  <div className="overlay-mobile" />
                </Layout>
                {isShowBtt && (
                  <div className="btt-custom-img">
                    <BackTop>
                      <img src={icon_btt} alt="icon_btt" />
                    </BackTop>
                  </div>
                )}

                <div className="btn_call">
                  <div className="btn_call-bgr" />
                  <a href="tel:1900558882">
                    <div className="btn_call-icon">
                      <div className="btn_call-icon_phone"></div>
                    </div>
                  </a>
                </div>
              </Layout>
              <NotificationContainer />
            </Layout>
          </>
        )}
      </I18nextProvider>
    </React.Fragment>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn || !!getUserSession()
  }
}
export default windowSize(withRouter(connect(mapStateToProps)(App)))
