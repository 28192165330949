import ApiService from './request-services'
import { FileServiceApi } from './request-services'


export const createPodcast = async data => {
  try {
    const body = data
    const results = await ApiService.post('/api/podcasts/create', body)
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcasts = async () => {
  try {
    const results = await ApiService.get('/api/podcasts')
    return results.data
  } catch (e) {
    throw e
  }
}

export const updatePodcast = async ({ id, data }) => {
  // console.log('updatePodcast: ', id, data)
  try {
    const results = await ApiService.put('/api/podcasts/update/' + id, data)
    // console.log('results', results.data)
    return results.data
  } catch (e) {
    throw e
  }
}

export const deletePodcast = async id => {
  try {
    // console.log("deletePodcast:id----- ", id)
    const results = await ApiService.delete('/api/podcasts/' + id)
    return results.data
  } catch (e) {
    throw e
  }
}
export const getPodcastsPerPage = async (page, size, categoryId) => {
  try {
    const results = await ApiService.get(`/api/podcasts/list`, {
      params: { page, size, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

// GET /podcast

export const getPodcastsPerKeyword = async (page, size, keyword, categoryId) => {
  try {
    const results = await ApiService.get(`/api/podcasts/list`, {
      params: { page, size, keyword, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const uploadImagePodcast = async (access_token, data, dir) => {
  try {
    const results = await ApiService.post(`/api/podcasts/upload-image?directory=${dir}`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        Authorization: `Bearer ${access_token}`
      }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteImagePodcast = async (path) => {
  try {
    const results = await ApiService.delete(`/api/podcasts/image?path=${path}` )
    return results.data
  } catch (e) {
    throw e
  }
}

export const deleteImageAndPodcastFile = async (dir) => {
  // console.log("deleteImageAndPodcastFile", podcast_file_id)
  try {
    const results = await ApiService.delete(`/api/podcasts/image-and-podcast-file?dir=${dir}`)
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcastsActive = async () => {
  try {
    const results = await ApiService.get('/api/podcasts/active')
    return results.data
  } catch (e) {
    throw e
  }
}

export const uploadPodcast = async (access_token, data) => {
  try {
    const results = await ApiService.post('/api/podcasts/upload', data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        Authorization: `Bearer ${access_token}`
      }
    })
    return results.data
  } catch (e) {
    throw e
  }
}


export const getStatisticPerDay = async (start_date, end_date) => {
  try {
    const results = await ApiService.get('/api/podcasts/statistics', {
      params: { start_date, end_date }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcastInfomation = async (uuid) => {
  // console.log("podcastId: ", podcastId)
  try {
    const results = await ApiService.get(`/api/podcasts/infomation`, {
      params: { uuid: uuid }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcastComment = async (page, size, podcast_id) => {
  try {
    const results = await ApiService.get(`/api/podcasts-comments`, {
      params: { page, size, podcast_id }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcastsPerPageAndViewSort = async (page, size, sort) => {
  try {
    // console.log("page, size, sort: ", page, size, sort)
    const results = await ApiService.get(`/api/podcasts/list`, {
      params: { page, size, sort }
    })
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleCommentInPodcast = async (payload) => {
  try {
    const results = await ApiService.post(`/api/podcasts-comments/create`, payload)
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleLikePodcast = async (id) => {
  try {
    const results = await ApiService.put('/api/podcasts/like-podcast/' + id )
    return results.data
  } catch (e) {
    throw e
  }
}

export const handleDislikePodcast = async (id) => {
  try {
    const results = await ApiService.put('/api/podcasts/dislike-podcast/' + id )
    return results.data
  } catch (e) {
    throw e
  }
}

export const downloadPodcast = async (uuid) => {
  try {
    const results = await FileServiceApi.get('/api/podcasts/download/' + uuid);
    return results.data
  } catch (e) {
    throw e
  }
}

export const getPodcastHistory = async (page, size, user_id, categoryId) => {
  try {
    const results = await ApiService.get(`/api/podcast-histories`, {
      params: { page, size, user_id, categoryId }
    })
    return results.data
  } catch (e) {
    throw e
  }
}