import axios from 'axios'
import { getUserSession, removeUserSessionWhenExpired, getLanguage } from '../utils/user-func'
import { message } from 'antd'

const ApiService = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  responseType: 'json'
})

const userSession = getUserSession()
ApiService.defaults.timeout = 300000
ApiService.defaults.headers.common['Accept-Language'] = getLanguage() ? getLanguage() : "vi"
ApiService.defaults.headers.common['Authorization'] = userSession ? 'Bearer ' + userSession['token'] : ''
ApiService.defaults.headers.post['Content-Type'] = 'application/json'

ApiService.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data && (response.data.error_code === 401 || response.data.error_code === 403)) {
      removeUserSessionWhenExpired()
    }
    if (response.data.status === "maintain" && window.location.pathname.indexOf("/maintain") !== 0) {
      window.location.href = "/maintain";
    }
    return response
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    message.error(error.toString())
    return Promise.reject(error)
  }
)

export default ApiService

const FileService = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  responseType: 'blob'
});
FileService.defaults.headers.common['Authorization'] = userSession ? 'Bearer ' + userSession['token'] : ''

export const FileServiceApi = FileService;
