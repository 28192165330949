import React from 'react'
import Loadable from 'react-loadable'
import { lazy } from 'react';
function Loading(props) {
  return <div className="vnt-loading"></div>
}

// COMMON

const AsyncLogin = lazy(() => import('../components/login-register/Login'));

const AsyncRegister = lazy(() => import('../components/login-register/Register'));

const AsyncForgotPassword = lazy(() => import('../components/login-register/ForgotPassword'));

const AsyncResetPassword = lazy(() => import('../components/login-register/ResetPassword'));

const AsyncChangePassword = lazy(() => import('../components/login-register/ChangePassword'));

const AsyncNotFound = Loadable({
  loader: () => import('../components/NotFound'),
  loading: Loading
})
// const AsyncNotFound = lazy(() => import('../components/NotFound'));

// CUSTOMER
const AsyncDashboard = Loadable({
  loader: () => import('../components/customer/Dashboard'),
  loading: Loading
})


const AsyncConfirmUserRegister = lazy(() => import('../components/customer/user-settings/ConfirmUserRegister'));

// ADMIN

const AsyncOverView = lazy(() => import('../components/admin/overview/OverView'));

const AsyncSalesAccManagement = lazy(() => import('../components/admin/saleAccManagement/saleAccManagement'));

const AsyncAdminPodcast = lazy(() => import('../components/admin/podcasts/index.js'));

const AsyncAdminCampaigns = lazy(() => import('../components/admin/campaigns/index.js'));

const AsyncAdminVideos = lazy(() => import('../components/admin/videos/index.js'));

const AsyncSendVerify =  lazy(() => import('../components/login-register/SendVerify'));

const AsyncMaintain = lazy(() => import('../components/Maintain'));

const AsyncUserManagement = lazy(() => import('../components/admin/user-management/UserManagement.js'));
 
const AsyncAddUser = lazy(() => import('../components/admin/user-management/AddUser'));


// new 


const AsyncVideo = lazy(() => import('../components/customer/videos/Video.js'));

const AsyncVideoDetail = lazy(() => import('../components/customer/videos/VideoDetail.js'));

const AsyncAboutUs = lazy(() => import('../components/customer/AboutUs.js'));

const AsyncProfile = lazy(() => import('../components/customer/Profile.js'));

const AsyncPodcast = lazy(() => import('../components/customer/Podcast.js'));

const AsyncPodcastDetail = lazy(() => import('../components/customer/PodcastDetail.js'));

const AsyncLifeTips = lazy(() => import('../components/customer/LifeTips.js'));

const AsyncLifeTipsDetail = lazy(() => import('../components/customer/life-tips/LifeTipsDetail.js'));

const AsyncLifeTipsDetail1 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail1.js'));

const AsyncLifeTipsDetail2 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail2.js'));

const AsyncLifeTipsDetail3 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail3.js'));

const AsyncLifeTipsDetail4 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail4.js'));

const AsyncLifeTipsDetail5 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail5.js'));

const AsyncLifeTipsDetail6 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail6.js'));

const AsyncLifeTipsDetail7 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail7.js'));

const AsyncLifeTipsDetail8 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail8.js'));

const AsyncLifeTipsDetail9 = lazy(() => import('../components/customer/life-tips/LifeTipsDetail9.js'));

const AsyncLearn = lazy(() => import('../components/customer/Learn.js'));

const AsyncLearnDetail = lazy(() => import('../components/customer/LearnDetail.js'));

const AsyncGame = lazy(() => import('../components/customer/Game.js'));

const AsyncGameDetail = lazy(() => import('../components/customer/GameDetail.js'));

const AsyncPingPong = lazy(() => import('../components/customer/PingPong.js'));

const AsyncPingPongDetail = lazy(() => import('../components/customer/PingPongDetail.js'));

export const appRoutes = [
  // common
  {
    path: '/user/verify',
    name: 'ConfirmUserRegister',
    component: AsyncConfirmUserRegister,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/send-verify',
    name: 'SendVerifyEmail',
    component: AsyncSendVerify,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/login',
    name: 'Login',
    component: AsyncLogin,
    exact: true,
    isProtected: false,
    layout: 'customer'
  },
  {
    path: '/register',
    name: 'Register',
    component: AsyncRegister,
    exact: false,
    layout: 'customer'
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: AsyncForgotPassword,
    exact: false,
    layout: 'customer'
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: AsyncResetPassword,
    exact: false,
    layout: 'customer'
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: AsyncChangePassword,
    exact: false,
    layout: 'customer'
  },

  // customer
  {
    path: '/',
    name: 'Home',
    component: AsyncDashboard,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: AsyncDashboard,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },


  // admin
  {
    path: '/administrator',
    name: 'AdminOverview',
    component: AsyncOverView,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/overview',
    name: 'AdminOverview OverView',
    component: AsyncOverView,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/account-management',
    name: 'SalesAccManagement',
    component: AsyncSalesAccManagement,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management',
    name: 'UserManagement',
    component: AsyncUserManagement,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/create-user',
    name: 'AddUser',
    component: AsyncAddUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/user-management/edit-user',
    name: 'EditUser',
    component: AsyncAddUser,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/podcasts',
    name: 'AdminSupport',
    component: AsyncAdminPodcast,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
 
  {
    path: '/administrator/videos',
    name: 'videos',
    component: AsyncAdminVideos,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },
  {
    path: '/administrator/banners',
    name: 'banners',
    component: AsyncAdminCampaigns,
    exact: true,
    isProtected: true,
    onlyAllow: ['admin'],
    layout: 'admin'
  },

  {
    path: '/maintain',
    name: 'Maintain',
    component: AsyncMaintain,
    exact: false,
    layout: ''
  },

  // new

  {
    path: '/video',
    name: 'Video',
    component: AsyncVideo,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/video/:id',
    name: 'VideoDetail',
    component: AsyncVideoDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AsyncAboutUs,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/profile',
    name: 'Profile',
    component: AsyncProfile,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/podcast',
    name: 'Podcast',
    component: AsyncPodcast,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/podcast/:id',
    name: 'Podcast Detail',
    component: AsyncPodcastDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips',
    name: 'Life Tips',
    component: AsyncLifeTips,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/1',
    name: 'Life Tips Detail',
    component: AsyncLifeTipsDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/2',
    name: 'Life Tips Detail1',
    component: AsyncLifeTipsDetail1,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/3',
    name: 'Life Tips Detail2',
    component: AsyncLifeTipsDetail2,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/4',
    name: 'Life Tips Detail3',
    component: AsyncLifeTipsDetail3,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/5',
    name: 'Life Tips Detail4',
    component: AsyncLifeTipsDetail4,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/6',
    name: 'Life Tips Detail5',
    component: AsyncLifeTipsDetail5,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/7',
    name: 'Life Tips Detail6',
    component: AsyncLifeTipsDetail6,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/8',
    name: 'Life Tips Detail7',
    component: AsyncLifeTipsDetail7,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/9',
    name: 'Life Tips Detail8',
    component: AsyncLifeTipsDetail8,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/life-tips/10',
    name: 'Life Tips Detail9',
    component: AsyncLifeTipsDetail9,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/learning',
    name: 'Learn',
    component: AsyncLearn,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/learning/:id',
    name: 'Learn Detail',
    component: AsyncLearnDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/game',
    name: 'Game',
    component: AsyncGame,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/game/:id',
    name: 'GameDetail',
    component: AsyncGameDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/pingpong',
    name: 'PingPong',
    component: AsyncPingPong,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },
  {
    path: '/pingpong/:id',
    name: 'PingPongDetail',
    component: AsyncPingPongDetail,
    exact: true,
    isProtected: false,
    onlyAllow: ['admin', 'customer'],
    layout: 'customer'
  },

  // 404



]
