import { GlobalOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Dropdown, Layout, Row, Select, message, TreeSelect } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import arrow_down from '../../assets/images/new-image/arrow-down.png'
import img_logo from '../../assets/images/new-image/logo-header.png'
import { getUserSession } from '../../utils/user-func'

import change_pw from '../../assets/images/new-image/change_pw.svg'
import icon_logout from '../../assets/images/new-image/icon-logout.svg'
import logo_default from '../../assets/images/new-image/logo-default.svg'

import icon_menu from '../../assets/images/new-image/icon-menu.svg'

import { useDispatch } from 'react-redux'
import { getVideosPerKeyword } from '../../api-services/video-services'
import { getPodcastsPerKeyword } from '../../api-services/podcast-services'
import { getLanguage } from '../../utils/user-func'

const { Header } = Layout

function Head(props) {
  const { t } = useTranslation('common')
  const user = getUserSession()
  const [data, setData] = useState([]) //videos
  const [dataPodcast, setDataPodcast] = useState([])
  const [valueSearch, setValueSearch] = useState()
  const [treeData, setTreeData] = useState()
  const [size, setSize] = useState(3)
  const [page, setPage] = useState(1)
  let timeout
  const { TreeNode } = TreeSelect;
  const history = useHistory()
  const dispatch = useDispatch()
  const initialData = [
    {
      label: 'Video',
      options: [],
    },
    {
      label: 'Podcast',
      options: [],
    }
  ]
  const menuActive = getLanguage()

  const items = [
    {
      key: '1',
      label: (
        <div className="dropdown-info">
          <div className="dropdown-info_title">{t('account')}</div>
          <Link to={'/profile'}>
            <img className="dropdown-info_img" src={logo_default} alt="logo_default" />
          </Link>
          <Link to={'/profile'} className="dropdown-info_subtitle">
            {t('page_person')}
          </Link>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <Link to="/change-password" className="dropdown-change_pw">
          <img src={change_pw} alt="change_pw" />
          {t('change_password')}
        </Link>
      )
    },
    {
      key: '3',
      label: user?.role.includes('admin') && (
        <Link to={'/administrator'} className="dropdown-change_pw">
          {t('switch_to_admin')}
        </Link>
      ),
      icon: user?.role.includes('admin') && <UserOutlined style={{ fontSize: '20px' }} />
    },
    {
      key: '4',
      label: (
        <div
          className="dropdown_logout"
          onClick={ e => {
            window.localStorage.removeItem('likeitis_user_client')
            window.location.reload()
            gotoHome()
          }}
        >
          <img src={icon_logout} alt="change_pw" />
          {t('log_out')}
        </div>
      )
    }
  ]

  const multiLanguage = [
    {
      key: '1',
      label: (
        <div className="dropdown-change_pw">
          <img src={change_pw} alt="change_pw" />
          {t('change_password')}
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div className="dropdown-change_pw">
          <img src={change_pw} alt="change_pw" />
          {t('change_password')}
        </div>
      )
    }
  ]

  const gotoProfile = () => {
    history.push('/profile')
  }
  const gotoHome = () => {
    history.push('/dashboard')
  }



  const handleChangeLanguage = language => {
    window.localStorage.setItem('language', language)

    window.location.reload()
  }

  const fetchDataVid = async newValue => {
    try {
      let response
      response = await getVideosPerKeyword(page, size, newValue)
      if (response.status === 'success') {
        setData(response.data)
        let child = []
        if (response.data.length > 0) {
          child = response.data.map(d => ({
            value: d.uuid,
            label: d.title,
          }))
        }
        let newData = {
          label: 'Video',
          options: child,
        }
        setTreeData(prevState => [newData, prevState?.[1]])
      } else {
        message.error(response.message || t('error_fetch_video'))
      }
    } catch (error) {
      message.error(t('error_fetch_video'))
    }
  }

  const fetchDataPod = async newValue => {
    try {
      let response
      response = await getPodcastsPerKeyword(page, size, newValue)
      if (response.status === 'success') {
        setDataPodcast(response.data)
        let child = []
        if (response.data.length > 0) {
          child = response.data.map(d => ({
            value: d.uuid,
            label: d.title
          }))
        }
        let newData = {
          label: 'Podcast',
          options: child,
        }
        setTreeData(prevState => [prevState?.[0], newData])
      } else {
        message.error(response.message || t('error_fetch_podcast'))
      }
    } catch (error) {
      message.error(t('error_fetch_podcast'))
    }
  }

  const handleSearch = newValue => {
    // console.log("newValue: ", newValue)
    if (newValue) {
      newValue.length === 1 && setTreeData(initialData)
      setValueSearch(newValue)
      // fetchDataVid(newValue)
      // fetchDataPod(newValue)
    } else {
      setValueSearch()
      setTreeData(initialData)
    }
  }
  useEffect(() => {
    if (valueSearch) {
      fetchDataVid(valueSearch)
      fetchDataPod(valueSearch)
    } else {
      setTreeData(initialData)
    }
  }, [valueSearch])
  // console.log("treeData: ", treeData)
  const handleChange = uuid => {
    if (uuid) {
      if (data.filter(x => x?.uuid === uuid).length > 0) {
        history.push('/video/' + uuid)
      } else if (dataPodcast.filter(x => x?.uuid === uuid).length > 0) {
        history.push('/podcast/' + uuid)
      }
    } else {
      setValueSearch()
    }
  }

  return (
    <Header className="header-custom">
      <Row justify="space-between" align="middle">
        <Col className="icon-menu-mobile">
          <img className="" src={icon_menu} alt="icon_menu" onClick={() => props.toggleTrueFalse()} />
        </Col>
        <Col className="header-logo">
          <img className="logo-header handle-click" src={img_logo} alt="logo-header" onClick={e => gotoHome(e)} />
        </Col>
        <Col className="header-noti-mobile">
          <div className="dropdown-custom">
            <div className="dropdown-toggle-custom">
              <GlobalOutlined style={{ fontSize: '30px', color: '#fff', cursor: 'pointer' }} />
            </div>
            <div className="dropdown-menu-custom">
              <div className="dropdown-menu-custom_hover">
                <div
                  className={`dropdown-menu-custom-item ${menuActive == 'vi' && 'menu-language-active'}`}
                  onClick={() => handleChangeLanguage('vi')}
                >
                  Việt Nam
                </div>
                <div
                  className={`dropdown-menu-custom-item ${menuActive == 'en' && 'menu-language-active'}`}
                  onClick={() => handleChangeLanguage('en')}
                >
                  English
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="header-search">
          <Select
            showSearch
            className="header-search-input"
            value={valueSearch}
            placeholder={t('search')}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={e => handleChange(e)}
            notFoundContent={null}
            // options={(data || []).map(d => ({
            //   value: d.uuid,
            //   label: d.title
            // }))}
            options={treeData}
            allowClear
          >
            {/* {treeData.map((node) => (
              <TreeNode title={node.title} value={node.value} key={node.value} style={{ backgroundColor: '#18224f', color: 'white' }}>
                {node.children &&
                  node.children.map((child) => (
                    <TreeNode title={child.title} value={child.value} key={child.value} style={{ backgroundColor: '#18224f', color: 'white' }} />
                  ))}
              </TreeNode>
            ))} */}
          </Select>
          <SearchOutlined
            style={{
              color: '#808191',
              fontSize: '24px',
              position: 'absolute',
              top: '24px',
              right: '12px'
            }}
          />
        </Col>
        <Col className="header-icon">
          <Row align="middle">
            {!user && (
              <Col>
                <Link to={'/login'} className="header-login">
                  {t('signin')}
                </Link>
              </Col>
            )}
            {user && (
              <Col>
                <Dropdown menu={{ items }}>
                  <Row align="middle" style={{ cursor: 'pointer' }}>
                    <img className="header-account-img" src={logo_default} alt="arrow-down" />
                    <Col className="header-account-name">{user ? user?.email : t('guest')}</Col>
                    <img className="header-account-btn" src={arrow_down} alt="arrow-down" />
                  </Row>
                </Dropdown>
              </Col>
            )}
            <Col className="header-noti">
              <div className="dropdown-custom">
                <div className="dropdown-toggle-custom">
                  <GlobalOutlined style={{ fontSize: '20px', color: '#fff', marginRight: '30px', cursor: 'pointer' }} />
                </div>
                <div className="dropdown-menu-custom">
                  <div className="dropdown-menu-custom_hover">
                    <div
                      className={`dropdown-menu-custom-item ${menuActive == 'vi' && 'menu-language-active'}`}
                      onClick={() => handleChangeLanguage('vi')}
                    >
                      Việt Nam
                    </div>
                    <div
                      className={`dropdown-menu-custom-item ${menuActive == 'en' && 'menu-language-active'}`}
                      onClick={() => handleChangeLanguage('en')}
                    >
                      English
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}

export default withRouter(Head)
