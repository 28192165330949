import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
// import img_goong_logo_2x from '../../assets/images/goong-logo-2x.png'
// import img_goong_logo_sb from '../../assets/images/goong-logo-mini.png'
// import avatar from '../../assets/images/avatar.png'
import { getUserSession, removeUserSession } from '../../utils/user-func'
import { showAvatar } from '../../utils/common'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { isTriggerResize } from '../../redux/actions/admin-action'
import { SIDE_BAR_ADMIN as SIDE_BAR_ADMIN_en } from '../../constants_en'
import { SIDE_BAR_ADMIN as SIDE_BAR_ADMIN_vi } from '../../constants_vi'
import { Layout, Menu, Divider } from 'antd'
import { LogoutOutlined, GlobalOutlined } from '@ant-design/icons'
import windowSize from 'react-window-size'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next';
import ApiService from '../../api-services/request-services'
import { useHistory } from 'react-router-dom'
// import img_logo from '../../assets/images/new-image/admin3.png'
import img_logo from '../../assets/images/new-image/logo-header-1.svg'
import img_logo_small from '../../assets/images/new-image/admin2.png'
const { Sider } = Layout

function SideBarAdmin(props) {
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedMobile, setCollapsedMobile] = useState(true)
  const { t, i18n } = useTranslation('common')
  const user = getUserSession()
  const SIDE_BAR_ADMIN = i18n.language === "en" ? SIDE_BAR_ADMIN_en : SIDE_BAR_ADMIN_vi
  const history = useHistory()
  useEffect(() => {
    if (props.windowWidth < 901) {
      setCollapsed(true)
      props.stateWidth('mobile')
    }
    if (props.windowWidth > 900) {
      props.stateWidth(collapsed)
    }
  }, [props.windowWidth, collapsed])

  useEffect(() => {
    props.dispatch(isTriggerResize(false))
  }, [])

  const handleClickLogOut = () => {
    removeUserSession()
    props.dispatch(isUserLoggedIn(false))
    history.push('/dashboard')
  }

  const toggleCollapsed = cs => {
    props.dispatch(isTriggerResize(cs))
    if (props.windowWidth < 901) {
      setCollapsedMobile(cs)
    } else {
      setCollapsed(cs)
    }
  }

  const onBackMenu = () => {
    if (props.windowWidth < 901) {
      setCollapsedMobile(true)
    }
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem("language", lang);
    ApiService.defaults.headers.common['Accept-Language'] = lang;
  }

  return (
    <React.Fragment>
      <Layout style={{ minHeight: '100vh' }} className="sidebar-admin-mobile">
        <Sider
          collapsible
          collapsed={props.windowWidth > 900 ? collapsed : collapsedMobile}
          onCollapse={toggleCollapsed}
          width={230}
          collapsedWidth={50}
          theme="light"
        >
          <Menu
            defaultSelectedKeys={
              props.location.pathname === '/administrator' ? '/administrator/overview' : props.location.pathname
            }
            mode="vertical"
            theme="light"
            className="sidebar-admin-content"
            id="sb-content"
          >
            <Link to={'/dashboard'} className="admin-sidebar-logo">
              {(props.windowWidth > 900 && collapsed) || (props.windowWidth < 901 && collapsedMobile) ? (
                <img src={img_logo_small} alt="goong-logo" className="icon-goong-sb-am" />
               
              ) : (
                <>
                  {/* <img src={img_goong_logo_2x} alt="like-it-is-logo" className="icon-goong-2x" /> */}
                  <img className='logo-header handle-click' style={{width: "230px"}} src={img_logo} alt="like-it-is-logo" />
                </>
              )}
            </Link>
            {(collapsed === false || collapsedMobile === false) && <Divider />}
            {/* <Link to={'/settings/profile'} className="avatar-admin-sb">
              <img
                src={user ? showAvatar(user?.avatar) : avatar}
                alt={'avatar'}
                className={
                  (props.windowWidth > 900 && collapsed) || (props.windowWidth < 901 && collapsedMobile)
                    ? 'avatar-sidebar-collapsed-admin'
                    : 'avatar-sidebar-admin'
                }
              />
              {(props.windowWidth > 900 && collapsed) || (props.windowWidth < 901 && collapsedMobile)
                ? ''
                : props.isLoggedIn && (
                  <span className="shortNameUserSideBarCustomer">{(user?.first_name || '') + ' ' + (user?.last_name || '')}</span>
                )}
            </Link> */}
            {SIDE_BAR_ADMIN.map(sb => {
              return (
                <Menu.Item key={sb.path}>
                  <Link to={sb.path} onClick={onBackMenu}>
                    {sb.iconSideBar}
                    <span>{sb.title}</span>
                  </Link>
                </Menu.Item>
              )
            })}
            <Menu.SubMenu
              key="language"
              title={t("language")}
              icon={<GlobalOutlined />}
            >
              <Menu.Item onClick={() => changeLanguage("vi")}>Tiếng Việt</Menu.Item>
              <Menu.Item onClick={() => changeLanguage("en")}>English</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="logout" onClick={handleClickLogOut}>
              <LogoutOutlined className="logoutIcon" />
              <span>{t("log_out")}</span>
            </Menu.Item>
          </Menu>
        </Sider>
      </Layout>
    </React.Fragment>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn
  }
}

export default windowSize(withRouter(connect(mapStateToProps)(SideBarAdmin)))
