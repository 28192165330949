import React, { useState, useEffect } from 'react'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { isAdmin, removeUserSession } from '../../utils/user-func'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_en } from '../../constants_en'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_vi } from '../../constants_vi'
import 'antd/dist/antd.css'
// import avatar from '../../assets/images/avatar.png'
// import img_goong_logo_2x from '../../assets/images/goong-logo-2x.png'
// import img_goong_logo_sb from '../../assets/images/goong-logo-mini.png'
import { Menu, Layout, Divider, Row, Col } from 'antd'
import { getUserSession, getLanguage } from '../../utils/user-func'
import windowSize from 'react-window-size'
import { showAvatar } from '../../utils/common'
import {
  UserSwitchOutlined,
  ContactsOutlined,
  LogoutOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  TableOutlined,
  LoginOutlined
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import ApiService from '../../api-services/request-services'

import home from '../../assets/images/new-image/sidebar-home.png'
import about_us from '../../assets/images/new-image/sidebar-about_us.png'
import game from '../../assets/images/new-image/sidebar-game.png'
import learning from '../../assets/images/new-image/sidebar-learning.png'
import life_tip from '../../assets/images/new-image/sidebar-life_tip.png'
import pingpong from '../../assets/images/new-image/sidebar-pingpong.png'
import podcast from '../../assets/images/new-image/sidebar-podcast.png'
import video from '../../assets/images/new-image/sidebar-video.png'
import logo_default from '../../assets/images/new-image/logo-default.svg'
import profile_call from '../../assets/images/new-image/profile-call.svg'
import profile_mail from '../../assets/images/new-image/profile-mail.svg'
import profile_location from '../../assets/images/new-image/profile-location.svg'

const { Sider } = Layout

function SideBarProfile(props) {
  const user = getUserSession()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedMobile, setCollapsedMoblie] = useState(true)

  const { t, i18n } = useTranslation('common')
  const SIDE_BAR_MOBILE_LINK = i18n.language === 'vi' ? SIDE_BAR_MOBILE_LINK_vi : SIDE_BAR_MOBILE_LINK_en
  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem('language', lang)
    ApiService.defaults.headers.common['Accept-Language'] = lang
  }

  useEffect(() => {
    if (props.windowWidth < 1200) {
      props.stateWidth(collapsed)
    }
    if (props.windowWidth < 1201) {
      setCollapsed(true)
    }
  }, [props.windowWidth, collapsed])

  const handleClickBacdrop = () => {
    if (props.windowWidth < 1201) {
      setCollapsedMoblie(true)
    }
  }

  const handleClickLogOut = () => {
    removeUserSession()
    props.dispatch(isUserLoggedIn(false))
    setCollapsed(true)
    setCollapsedMoblie(true)
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    setCollapsedMoblie(!collapsedMobile)
    if (props.windowWidth > 1200) {
      setCollapsedMoblie(true)
    }
  }
  // console.log("SideBar--------------", props, props.isLoggedIn)
  return (
    <div>
      <React.Fragment>
        {/* <Layout style={{ minHeight: '100vh' }} className="sidebar-customer-mobile"> */}
        <Sider
          collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
          width={234}
          collapsedWidth={0}
          theme="dark"
          breakpoint="lg"
        >
          <div className="sidebar-profile">
            <Row align="middle" style={{}} className="sidebar-profile-content">
              <Col className="sidebar-profile_img">
                  <img src={logo_default} alt="logo_default" />
              </Col>
              <Col className="profile-name">
                  {user?.user_profile?.first_name}&nbsp;{user?.user_profile?.last_name}
              </Col>
              <Col className="profile-email">{user?.email ? user?.email : '--'}</Col>
              <Col className="profile-phone">
                {user?.user_profile?.phone_number ? user?.user_profile?.phone_number : '--'}
              </Col>
              <Col className="sidebar-profile-content_line" />
              <Col className="profile-contact w-100">{t('info_contact')}</Col>
              <Col className="profile-contact-item">
                <Row wrap={false}>
                  <Col>
                    <img className="profile-contact-item_img" src={profile_call} alt="profile_call" />
                  </Col>
                  <Col>{user?.user_profile?.phone_number ? user?.user_profile?.phone_number : '--'}</Col>
                </Row>
                <Row wrap={false}>
                  <Col style={{ overflowWrap: 'anywhere' }}>
                    <img className="profile-contact-item_img" src={profile_mail} alt="profile_mail" />
                  </Col>
                  <Col style={{ overflowWrap: 'anywhere' }}>{user?.email ? user?.email : '--'}</Col>
                </Row>
                <Row wrap={false}>
                  <Col style={{ overflowWrap: 'anywhere' }}>
                    <img className="profile-contact-item_img" src={profile_location} alt="profile_location" />
                  </Col>
                  <Col>--</Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Sider>
        {/* </Layout> */}
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn
  }
}

export default windowSize(withRouter(connect(mapStateToProps)(SideBarProfile)))
