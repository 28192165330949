import moment from 'moment'
import { message } from 'antd';

export const setDataTip = (inputElement, text, duration = 5) => {
  if (inputElement && inputElement instanceof HTMLElement) {
    inputElement.classList.add("border-red");
    [...inputElement.parentElement.childNodes].map(node => {
      if (node.classList.contains('p-explain')) node.remove();
    })
    var para = document.createElement("p");
    para.classList.add('p-explain');
    para.innerHTML = text;
    inputElement.parentElement.appendChild(para);
    setTimeout(function () {
      para.remove();
      inputElement.classList.remove("border-red");
    }, duration * 1000)
  }

}

export const setDataTipDashboard = (inputElement, text, duration = 5) => {
  inputElement.classList.add("border-red");
  [...inputElement.parentElement.childNodes].map(node => {
    if (node.classList.contains('p-explain_dashboard')) node.remove();
  })
  var para = document.createElement("p");
  para.classList.add('p-explain_dashboard');
  para.innerHTML = text;
  inputElement.parentElement.appendChild(para);
  setTimeout(function () {
    para.remove();
    inputElement.classList.remove("border-red");
  }, duration * 1000)
}

export const calculateDateAgo = date => {
  const lang = window.localStorage.getItem("language");
  let str = ''
  if (lang === "en") {
    const diff_days = moment().diff(moment(date), 'days')
    if (diff_days === 0) {
      const diff_hour = moment().diff(moment(date), 'hours')
      const diff_minute = moment().diff(moment(date), 'minutes')
      if (diff_hour < 1) {
        if (diff_minute < 1) {
          str = `Just now`
        } else {
          str = `About ${diff_minute} minutes ago`
        }
      } else {
        str = `About ${diff_hour} hours ago`
      }
    } else if (diff_days > 0 && diff_days < 7) {
      str = `About ${diff_days}  ${diff_days === 1 ? 'day' : 'days'} ago`
    } else if (diff_days >= 7 && diff_days < 30) {
      const diff_week = moment().diff(moment(date), 'weeks')
      str = `About ${diff_week} ${diff_week === 1 ? 'week' : 'weeks'} ago`
    } else if (diff_days === 30) {
      str = `About 1 month ago`
    } else {
      const diff_month = moment().diff(moment(date), 'months')
      str = `About ${diff_month} ${diff_month === 1 ? 'month' : 'months'} ago`
    }
  } else {
    const diff_days = moment().diff(moment(date), 'days')
    if (diff_days === 0) {
      const diff_hour = moment().diff(moment(date), 'hours')
      const diff_minute = moment().diff(moment(date), 'minutes')
      if (diff_hour < 1) {
        if (diff_minute < 1) {
          str = `Gần đây`
        } else {
          str = `${diff_minute} phút trước`
        }
      } else {
        str = `${diff_hour} giờ trước`
      }
    } else if (diff_days > 0 && diff_days < 7) {
      str = `${diff_days}  ngày trước`
    } else if (diff_days >= 7 && diff_days < 30) {
      const diff_week = moment().diff(moment(date), 'weeks')
      str = `${diff_week} tuần trước`
    } else if (diff_days === 30) {
      str = `1 tháng trước`
    } else {
      const diff_month = moment().diff(moment(date), 'months')
      str = `${diff_month} tháng trước`
    }
  }
  return str
}

export const showStaticImage = path => {
  if (path) {
    return process.env.REACT_APP_SERVICE_URL + path
  }
  return ''
}

export const showAvatar = link => {
  if (link) {
    if (link.includes('http://') || link.includes('https://')) {
      return link
    } else {
      // => path      
      return process.env.REACT_APP_SERVICE_URL + "/static/" + link
    }
  }
  const img_admin_avatar = require('../assets/images/user-avatar.png')
  return img_admin_avatar
}


/*
 Perfect function to cancel able any promise
 To fix: Warning: Can't call setState (or forceUpdate) on an unmounted component.
 This is a no-op, but it indicates a memory leak in your application.
 To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
 */
export const makeCancelable = promise => {
  let hasCanceled_ = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    )
  })
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    }
  }
}

export function showShortName(user) {
  let short_name = ''
  if (user) {
    const { first_name, last_name } = user
    if (first_name) {
      short_name = first_name[0]
    }
    if (last_name) {
      short_name += last_name[0]
    }
  }
  return short_name
}

export function copyToClipboard(secretInfo) {
  const $body = document.getElementsByTagName('body')[0]
  const $tempInput = document.createElement('INPUT')
  $body.appendChild($tempInput)
  $tempInput.setAttribute('value', secretInfo)
  $tempInput.select()
  document.execCommand('copy')
  $body.removeChild($tempInput)
  message.success(`'${secretInfo}' đã được sao chép`, 5);
}

export function validatePhoneNumber(phone) {
  // const re = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  // return re.test(String(phone).toLowerCase());

  try {
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(phone, 'VN');
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (e) {
    return false;
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const formatCoupon = coupon => {
  return `${coupon.slice(0, 3)}-${coupon.slice(3, 6)}-${coupon.slice(6, 9)}`
}

export const checkValidateRestriction = (type, value) => {
  let regex;
  if (type === "ip") {
    regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(value);
  }
  regex = /^http\*:\/\/(\*\.)?([a-z\d][a-z\d-]*[a-z\d]\.)+[a-z\/\*]+$/;
  return regex.test(value);
}

export const checkValidateName = (name) => {
  const regex = /[0-9`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/;
  return !regex.test(name);
}

export const customFormat = (number, separator = ',', groupSize = 3) => {
  if (number) {
    // Chuyển số thành chuỗi
    let numberString = number.toString();

    // Tách phần nguyên và phần thập phân
    let parts = numberString.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Định dạng phần nguyên
    let formattedInteger = '';
    let count = 0;
    for (let i = integerPart.length - 1; i >= 0; i--) {
      count++;
      formattedInteger = integerPart[i] + formattedInteger;
      if (count === groupSize && i !== 0) {
        formattedInteger = separator + formattedInteger;
        count = 0;
      }
    }

    // Kết hợp phần nguyên và phần thập phân và trả về
    return formattedInteger + decimalPart;
  } else { return null }
}


export const convertMultipleLanguage = (arr) => {
  arr.forEach(e => {
    switch (e.name) {
      case "Phòng chống bạo lực":
        return e.name = "tab_catefory_1"
      case "Giới tính":
        return e.name = "tab_catefory_6"
      case "Quá trình thụ thai":
        return e.name = "tab_catefory_2"
      case "Tình dục an toàn":
        return e.name = "tab_catefory_3"
      case "Biện pháp tránh thai":
        return e.name = "tab_catefory_4"
      case "Phòng chống bạo lực":
        return e.name = "tab_catefory_5"
      case "Giới tính":
        return e.name = "tab_catefory_6"
      case "Tình dục an toàn":
        return e.name = "tab_catefory_7"
      case "Quá trình thụ thai":
        return e.name = "tab_catefory_8"
      case "Biện pháp tránh thai":
        return e.name = "tab_catefory_9"
      case "Tất cả":
        return e.name = "tab_catefory_10"
    }
  });
  return arr
}

