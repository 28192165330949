import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import img_logo from '../../assets/images/new-image/logo-footer.png'
import fb_logo from '../../assets/images/new-image/social-fb.svg'
import ig_logo from '../../assets/images/new-image/social-ig.svg'
import tiktok_logo from '../../assets/images/new-image/social-tiktok.svg'
import youtube_logo from '../../assets/images/new-image/social-youtube.svg'
import wiki_logo from '../../assets/images/new-image/social-wiki.svg'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'

function Footer(props) {
  const { location } = props.history
  const { t } = useTranslation('common')

  return (
    <footer>
      <section className="footer-custom">
        <Row>
          <Col span={24}>
            <Row justify="space-between" align="top" gutter={[0, 24]}>
              <Col className='footer-custom_logo'>
                <Link to={'/dashboard'}>
                  <img src={img_logo} alt={'msi-logo'} />
                </Link>
              </Col>
              <Col>
                <Row style={{ maxWidth: '88px' }}>
                  <Col span={24}>
                    <div className="footer-title">{t('learn_more')}</div>
                  </Col>
                  <Col span={24}>
                    <Link to={'/dashboard'} className="footer-subtitle">{t('home')}</Link>
                  </Col>
                  <Col span={24}>
                    <Link to={'/video'} className="footer-subtitle">{t('video')}</Link>
                  </Col>
                  <Col span={24}>
                    <Link to={'/podcast'} className="footer-subtitle">{t('podcast')}</Link>
                  </Col>
                  <Col span={24}>
                    <Link to={'/learning'} className="footer-subtitle">{t('learning')}</Link>
                  </Col>
                  <Col span={24}>
                    <Link to={'/life-tips'} className="footer-subtitle">{t('life_tip')}</Link>
                  </Col>
                  <Col span={24}>
                    <Link to={'/game'} className="footer-subtitle">{t('game')}</Link>
                  </Col>
                  <Col span={24}>
                    <div className="footer-subtitle">{t('pingpong')}</div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row style={{ maxWidth: '380px' }}>
                  <Col span={24}>
                    <div className="footer-title">{t('contact')}</div>
                  </Col>
                  <Col span={24} className='footer-item'>
                    <Row style={{
                      flexWrap: 'nowrap',
                      alignItems: 'baseline'
                    }}>
                      <Col>
                        <div className="footer-address">{t('address')}:</div>
                      </Col>
                      <Col>
                        <a target='_blank' href='https://maps.app.goo.gl/WLXuwZTpN1Ffhyft8' className="footer-sub_address">{t('address_detail')}</a>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row style={{
                      flexWrap: 'nowrap',
                      alignItems: 'baseline'
                    }}>
                      <Col>
                        <div className="footer-address">{t('phone_number')}:</div>
                      </Col>
                      <Col>
                        <a href='tel:1900558882' className="footer-sub_address">{t('phone_number_detail')}</a>
                      </Col>
                      {/*  */}
                    </Row>
                  </Col>
                  <Col span={24} className='footer-item'>
                    <Row style={{
                      flexWrap: 'nowrap',
                      alignItems: 'baseline'
                    }}>
                      <Col>
                        <div className="footer-address">Email:</div>
                      </Col>
                      <Col>
                      <a href="mailto:info.msivn@msichoices.org.vn" className="footer-sub_address">info.msivn@msichoices.org.vn</a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row style={{ width: '192px' }}>
                  <Col span={24}>
                    <div className="footer-title">{t('social')}</div>
                  </Col>
                  <Col span={24} className='footer-social'>
                    <Row justify="space-between" align="middle">
                      <Col>
                        <a target='_blank' href='https://www.facebook.com/MSIchoicesvietnam'> <img className="footer-img-icon" src={fb_logo} alt={'fb-logo'} /> </a>
                      </Col>
                      <Col>
                        <a target='_blank' href='https://www.instagram.com/msivnhoptacsuckhoedoanhnghiep/'> <img className="footer-img-icon" src={ig_logo} alt={'ig-logo'} /> </a>
                      </Col>
                      <Col>
                        <a target='_blank' href='https://www.tiktok.com/@spkdrmarie'> <img className="footer-img-icon" src={tiktok_logo} alt={'tiktok_logo'} style={{ width: '20px', height: '20px' }} /> </a>
                      </Col>
                      <Col>
                        <a target='_blank' href='https://www.youtube.com/playlist?list=PLHXT1zVYtUkVE37KqSMlUYzUp0flZcZO_'> <img className="footer-img-icon" src={youtube_logo} alt={'youtube-logo'} /> </a>
                      </Col>
                      <Col>
                        <a target='_blank' href='https://www.linkedin.com/company/msi-reproductive-choicesunv/?originalSubdomain=uk'> <img className="footer-img-icon" src={wiki_logo} alt={'wiki-logo'} /> </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section >
    </footer >
  )
}

export default withRouter(Footer)
