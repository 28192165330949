import React from 'react'
import {
  ContactsOutlined,
  UsergroupDeleteOutlined,
  ControlOutlined,
  KeyOutlined,
  AreaChartOutlined,
  AuditOutlined,
  WhatsAppOutlined,
  UserOutlined,
  LockOutlined,
  DeploymentUnitOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  LineChartOutlined,
  SettingOutlined,
  NotificationOutlined,
  PlayCircleOutlined,
  CreditCardOutlined,
  ProjectOutlined,
  DollarOutlined,
  PicLeftOutlined,
  CompassOutlined,
  MailOutlined
} from '@ant-design/icons'
import { wrap } from 'lodash'
import { getLanguage } from './utils/user-func'

export const USER_SESSION_KEY = 'likeitis_user_client'
export const MONEY_TO_VNP = 'money_to_vnpay'

export const CHART_TYPE = {
  CALL_API: {
    text: 'Api Request Total',
    title: 'APIs'
  },
  LOAD_MAP: {
    text: 'Map Load Total',
    title: 'MAP'
  },
  GEOCODE: {
    text: 'Geocode',
    title: ''
  },
  FORWARD_GEOCODE: {
    text: 'Forward geocode',
    title: ''
  },
  REVERSE_GEOCODE: {
    text: 'Reverse geocode',
    title: ''
  },
  DIRECTION: {
    text: 'Directions',
    title: ''
  },
  DISTANCE_MATRIX: {
    text: 'Distance matrix',
    title: ''
  },
  PLACE_DETAIL: {
    text: 'Place detail',
    title: ''
  },
  AUTOCOMPLETE: {
    text: 'Autocomplete',
    title: ''
  },
  FIND_PLACE_FROM_TEXT: {
    text: 'Find Place_from_text',
    title: ''
  },
  SESSION_AUTOCOMPLETE: {
    text: 'Session autocomplete',
    title: ''
  },
  TRIP: {
    text: 'Trip',
    title: ''
  }
}

export const ITEM_DATASET_DEFAULT = {
  label: '',
  data: [],
  fill: false,
  lineTension: 0.4,
  backgroundColor: '#3A93F9',
  borderColor: '#3A93F9',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: '#3A93F9',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: '#3A93F9',
  pointHoverBorderColor: '#3A93F9',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10
  // xAxisID: 0,
  // yAxisID: 0,
}

export const PIE_OPTIONS = {
  legend: {
    display: true,
    position: 'left',
    labels: {
      generateLabels: function(chart) {
        var labels = chart?.data?.labels
        var data = chart?.data?.datasets[0]
        return labels.map((item, i) => {
          return {
            text: item + ': ' + data?.data[i]?.toLocaleString(getLanguage()),
            fillStyle: data?.backgroundColor[i],
            lineWidth: data?.borderWidth
          }
        })
      },
      fontSize: 18,
      fontColor: 'black'
    }
  },
  plugins: {
    labels: [
      {
        render: function(args) {
          if (args.percentage > 10) {
            return `${args.label}\n${args.percentage?.toLocaleString(getLanguage())}%`
          }
        },

        fontColor: 'white',
        fontSize: 14,
        precision: 2
      }
    ]
  },
  maintainAspectRatio: false
}

export const ITEM_PIE_DATASET_DEFAULT = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgb(54, 162, 235)',
        'rgb(255, 206, 86)',
        'rgb(75, 192, 192)',
        'rgb(153, 102, 255)',
        'rgb(255, 109, 64)',
        'rgb(145, 59, 10)',
        'red',
        'green',
        'blue',
        'purple',
        '#ff3333',
        '#3366ff',
        '#33cc33',
        '#cc0066',
        '#336699',
        '#ff99ff',
        'orange',
        'green',
        '#ff0055',
        '#009933',
        '#0033cc',
        '#3366cc'
      ],
      borderWidth: 0,
      datalabels: {
        color: 'blue',
        labels: {
          title: {
            font: {
              weight: 'bold'
            }
          },
          value: {
            color: 'green'
          }
        }
      }
    }
  ]
}

export const ITEM_DATASET_PIE = {
  label: [],
  data: [],
  fill: false,
  backgroundColor: [
    'rgb(54, 162, 235)',
    'rgb(255, 206, 86)',
    'rgb(75, 192, 192)',
    'rgb(153, 102, 255)',
    'rgb(255, 109, 64)',
    'rgb(145, 59, 10)',
    'red',
    'green',
    'blue',
    'purple',
    '#ff3333',
    '#ff3333',
    '#3366ff',
    '#33cc33',
    '#cc0066',
    '#336699',
    '#ff99ff',
    'orange',
    'green',
    '#ff0055',
    '#009933',
    '#0033cc',
    '#3366cc'
  ],
  borderWidth: 0
}

export const TOKEN_ORIGIN_LIST = ['https://editor.goong.io', 'http://localhost:8888']

export const SIDE_BAR_ADMIN = [
  {
    type: 'OVERVIEW',
    path: '/administrator/overview',
    activeWith: ['/administrator', '/administrator/overview'],
    title: 'Over View',
    icon: function() {
      return (
        <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <mask id="path-1-inside-1" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.984 0.600006H18V6.60001H9.984V0.600006ZM9.984 18.6V8.61601H18V18.6H9.984ZM0 18.6V12.6H8.016V18.6H0ZM0 10.584V0.600006H8.016V10.584H0Z"
              />
            </mask>
            <path
              d="M9.984 0.600006V-0.799994H8.584V0.600006H9.984ZM18 0.600006H19.4V-0.799994H18V0.600006ZM18 6.60001V8.00001H19.4V6.60001H18ZM9.984 6.60001H8.584V8.00001H9.984V6.60001ZM9.984 18.6H8.584V20H9.984V18.6ZM9.984 8.61601V7.21601H8.584V8.61601H9.984ZM18 8.61601H19.4V7.21601H18V8.61601ZM18 18.6V20H19.4V18.6H18ZM0 18.6H-1.4V20H0V18.6ZM0 12.6V11.2H-1.4V12.6H0ZM8.016 12.6H9.416V11.2H8.016V12.6ZM8.016 18.6V20H9.416V18.6H8.016ZM0 10.584H-1.4V11.984H0V10.584ZM0 0.600006V-0.799994H-1.4V0.600006H0ZM8.016 0.600006H9.416V-0.799994H8.016V0.600006ZM8.016 10.584V11.984H9.416V10.584H8.016ZM9.984 2.00001H18V-0.799994H9.984V2.00001ZM16.6 0.600006V6.60001H19.4V0.600006H16.6ZM18 5.20001H9.984V8.00001H18V5.20001ZM11.384 6.60001V0.600006H8.584V6.60001H11.384ZM11.384 18.6V8.61601H8.584V18.6H11.384ZM9.984 10.016H18V7.21601H9.984V10.016ZM16.6 8.61601V18.6H19.4V8.61601H16.6ZM18 17.2H9.984V20H18V17.2ZM1.4 18.6V12.6H-1.4V18.6H1.4ZM0 14H8.016V11.2H0V14ZM6.616 12.6V18.6H9.416V12.6H6.616ZM8.016 17.2H0V20H8.016V17.2ZM1.4 10.584V0.600006H-1.4V10.584H1.4ZM0 2.00001H8.016V-0.799994H0V2.00001ZM6.616 0.600006V10.584H9.416V0.600006H6.616ZM8.016 9.18401H0V11.984H8.016V9.18401Z"
              fill="#566571"
              mask="url(#path-1-inside-1)"
            />
          </g>
        </svg>
      )
    },
    iconSideBar: <AppstoreOutlined />
  },
  {
    type: 'SALES_MANAGEMENT',
    path: '/administrator/account-management',
    activeWith: ['/administrator/account-management'],
    title: 'Acc Management',
    icon: function() {
      return (
        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <mask id="path-1-inside-1" fill="white">
              <path d="M11.0001 8.54546V11.8182L7.56372 14.3545" />
            </mask>
            <path
              d="M11.0001 11.8182L11.8315 12.9446L12.4001 12.5249V11.8182H11.0001ZM9.60008 8.54546V11.8182H12.4001V8.54546H9.60008ZM10.1687 10.6918L6.73233 13.2281L8.39511 15.481L11.8315 12.9446L10.1687 10.6918Z"
              fill="#566571"
              mask="url(#path-1-inside-1)"
            />
            <mask id="path-3-inside-2" fill="white">
              <path d="M11 11.8182L14.4364 14.3545L11 11.8182Z" />
            </mask>
            <path
              d="M10.1686 12.9446L13.605 15.4809L15.2678 13.2281L11.8314 10.6918L10.1686 12.9446Z"
              fill="#566571"
              mask="url(#path-3-inside-2)"
            />
            <path
              d="M11 9.24545C13.1941 9.24545 14.9727 7.4668 14.9727 5.27273C14.9727 3.07865 13.1941 1.3 11 1.3C8.80595 1.3 7.02729 3.07865 7.02729 5.27273C7.02729 7.4668 8.80595 9.24545 11 9.24545Z"
              stroke="#566571"
              strokeWidth="1.4"
            />
            <path
              d="M5.27273 20.7C7.4668 20.7 9.24545 18.9213 9.24545 16.7273C9.24545 14.5332 7.4668 12.7545 5.27273 12.7545C3.07865 12.7545 1.3 14.5332 1.3 16.7273C1.3 18.9213 3.07865 20.7 5.27273 20.7Z"
              stroke="#566571"
              strokeWidth="1.4"
            />
            <path
              d="M16.7273 20.7C18.9214 20.7 20.7 18.9213 20.7 16.7273C20.7 14.5332 18.9214 12.7545 16.7273 12.7545C14.5332 12.7545 12.7546 14.5332 12.7546 16.7273C12.7546 18.9213 14.5332 20.7 16.7273 20.7Z"
              stroke="#566571"
              strokeWidth="1.4"
            />
          </g>
        </svg>
      )
    },
    iconSideBar: <DeploymentUnitOutlined />
  },
  {
    type: 'USER_MANAGEMENT',
    path: '/administrator/user-management',
    activeWith: [
      '/administrator/user-management',
      '/administrator/user-management/create-user',
      '/administrator/user-management/edit-user'
    ],
    title: 'Account Management',
    icon: function() {
      return (
        <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path
              d="M8.71429 11.8C6.29371 11.8 4.14657 12.3742 2.73571 12.8737C1.69429 13.2436 1 14.2741 1 15.4288V19C1 19 9.53543 19 9.57143 19"
              stroke="#566571"
              strokeWidth="1.4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.71418 11.8V11.8C6.34761 11.8 4.42847 8.8849 4.42847 6.4V5.5C4.42847 3.0151 6.34761 1 8.71418 1V1C11.0808 1 12.9999 3.0151 12.9999 5.5V6.4C12.9999 8.8849 11.0808 11.8 8.71418 11.8Z"
              stroke="#566571"
              strokeWidth="1.4"
              strokeLinecap="square"
            />
            <path
              d="M12.3333 13.3333H13.6666V18.6667H18.3333L19.6666 15.3333H13.6666"
              stroke="#566571"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.9999 21C14.3681 21 14.6666 20.7015 14.6666 20.3333C14.6666 19.9651 14.3681 19.6667 13.9999 19.6667C13.6317 19.6667 13.3333 19.9651 13.3333 20.3333C13.3333 20.7015 13.6317 21 13.9999 21Z"
              fill="#566571"
            />
            <path
              d="M18.6667 21C19.0349 21 19.3333 20.7015 19.3333 20.3333C19.3333 19.9651 19.0349 19.6667 18.6667 19.6667C18.2985 19.6667 18 19.9651 18 20.3333C18 20.7015 18.2985 21 18.6667 21Z"
              fill="#566571"
            />
          </g>
        </svg>
      )
    },
    iconSideBar: <UserOutlined />
  },
  {
    type: 'VIDEOS',
    path: '/administrator/videos',
    activeWith: ['/administrator/videos'],
    title: 'Video',
    icon: function() {
      return (
        <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6 8.82609V6.47826C14.6 3.4527 12.0928 1 9 1C5.90721 1 3.4 3.4527 3.4 6.47826V8.82609C3.4 11.4087 1 12.0348 1 13.5217C1 14.8522 4.12 15.8696 9 15.8696C13.88 15.8696 17 14.8522 17 13.5217C17 12.0348 14.6 11.4087 14.6 8.82609Z"
              stroke="#566571"
              strokeWidth="1.4"
              strokeLinecap="square"
            />
            <path
              d="M9.00006 17.4348C8.19126 17.4348 7.43126 17.4082 6.71606 17.3565C7.029 18.333 7.95393 18.9976 9.00006 18.9976C10.0462 18.9976 10.9711 18.333 11.2841 17.3565C10.5689 17.4082 9.80886 17.4348 9.00006 17.4348Z"
              fill="#566571"
            />
          </g>
        </svg>
      )
    },
    iconSideBar: <PlayCircleOutlined />
  },
  {
    type: 'PODCAST',
    path: '/administrator/podcasts',
    activeWith: ['/administrator/podcasts'],
    title: 'Podcast',
    icon: function () {
      return <UsergroupDeleteOutlined />
    },
    iconSideBar: <UsergroupDeleteOutlined />
  },
  {
    type: 'BANNERS',
    path: '/administrator/banners',
    activeWith: ['/administrator/banners'],
    title: 'Banners',
    icon: function() {
      return (
        <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6 8.82609V6.47826C14.6 3.4527 12.0928 1 9 1C5.90721 1 3.4 3.4527 3.4 6.47826V8.82609C3.4 11.4087 1 12.0348 1 13.5217C1 14.8522 4.12 15.8696 9 15.8696C13.88 15.8696 17 14.8522 17 13.5217C17 12.0348 14.6 11.4087 14.6 8.82609Z"
              stroke="#566571"
              strokeWidth="1.4"
              strokeLinecap="square"
            />
            <path
              d="M9.00006 17.4348C8.19126 17.4348 7.43126 17.4082 6.71606 17.3565C7.029 18.333 7.95393 18.9976 9.00006 18.9976C10.0462 18.9976 10.9711 18.333 11.2841 17.3565C10.5689 17.4082 9.80886 17.4348 9.00006 17.4348Z"
              fill="#566571"
            />
          </g>
        </svg>
      )
    },
    iconSideBar: <PicLeftOutlined />
  }
 
]
export const CHART_LINE_OPTION = {
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem) {
        return tooltipItem.yLabel
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  maintainAspectRatio: false
}
const toPascalCase = str => {
  let name = ''
  for (let i in str) {
    if (i == 0 || str[i - 1] == ' ') {
      name += str[i].toUpperCase()
    } else if (i > 10) {
      name += '...'
      return name
    } else {
      name += str[i].toLowerCase()
    }
  }
  return name
}

export const CHART_DOUGHNUT_OPTION = {
  legend: {
    display: true,
    position: 'left',
    // markerType: "circle",
    // usePointStyle: 'circle',
    // symbolHeight: 12,
    // symbolWidth: 12,
    // symbolRadius: 0,
    // squareSymbol: false,
    
    fontSize: 4,
    labels: {
      boxWidth: 10,
      // This more specific font property overrides the global property
      // fontColor: 'rgb(255, 99, 132)',
      generateLabels: function(chart) {
        var labels = chart?.data?.labels
        var data = chart?.data?.datasets[0]
        return labels.map((item, i) => {
          return {
            text:
              toPascalCase(item.replaceAll('_', ' ')) +
              ': ' +
              data?.data[i]?.toLocaleString(getLanguage()),
            fillStyle: data?.backgroundColor[i],
            lineWidth: data?.borderWidth
          }
        })
      },
      fontSize: 16,
      fontColor: '#566571',
      fontSize: 10
    }
  },
  maintainAspectRatio: false
}

export const CHART_PIE_OPTION = {
  legend: {
    display: true,
    position: 'left',
    labels: {
      generateLabels: function(chart) {
        var labels = chart?.data?.labels
        var data = chart?.data?.datasets[0]
        return labels.map((item, i) => {
          return {
            text: item + ': ' + data?.data[i]?.toLocaleString(getLanguage()),
            fillStyle: data?.backgroundColor[i],
            lineWidth: data?.borderWidth
          }
        })
      },
      fontSize: 16,
      fontColor: '#566571'
    }
  },
  plugins: {
    labels: [
      {
        render: function(args) {
          if (args.percentage > 10) {
            return `${args.label}\n${args.percentage?.toLocaleString(getLanguage())}%`
          }
        },

        fontColor: '#000',
        fontSize: 13,
        precision: 2
      }
    ]
  },
  maintainAspectRatio: false
}

export const CHART_DOUGHNUT_OPTION_MOBILE = {
  legend: {
    display: true,
    position: 'top',
    fontSize: 5,
    labels: {
      fontSize: 12
    }
  },
  maintainAspectRatio: false
}

export const FREE_REMAINING = 100

export const SIDE_BAR_MOBILE_LINK = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: <ControlOutlined />
  },
  {
    text: 'Keys',
    path: '/keys',
    icon: <KeyOutlined />
  },
  {
    text: 'My Map',
    path: '/maps',
    icon: <CompassOutlined />
  },
  {
    text: 'Statistics',
    path: '/statistics',
    icon: <AreaChartOutlined />
  },
  {
    text: 'Payment history',
    path: '/payment',
    icon: <DollarOutlined />
  },
  {
    text: 'Billing',
    path: '/billing',
    icon: <AuditOutlined />
  },
  {
    text: 'Support',
    path: '/support/request',
    icon: <WhatsAppOutlined />
  },
  {
    text: 'Profile',
    path: '/settings/profile',
    icon: <UserOutlined />
  },
  {
    text: 'Change password',
    path: '/settings/change-password',
    icon: <LockOutlined />
  },
  {
    text: 'Security',
    path: '/settings/sercurity',
    icon: <DeploymentUnitOutlined />
  }
]

export const KEY_TYPE = {
  API_KEY: 'rsapi',
  MAPTILES_KEY: 'tile-server'
}
