export const toggleSignInPopup = isShowSignInPopup => ({
  type: 'TOGGLE_SIGN_IN_POPUP',
  isShowSignInPopup: isShowSignInPopup
})

export const toggleSignUpPopup = isShowSignUpPopup => ({
  type: 'TOGGLE_SIGN_UP_POPUP',
  isShowSignUpPopup: isShowSignUpPopup
})

export const isUserLoggedIn = boolean => ({
  type: 'IS_USER_LOGGED_IN',
  isLoggedIn: boolean
})

export const setTimeUserChangeProfile = timeChangeProfile => ({
  type: 'TIME_USER_CHANGE_PROFILE',
  timeChangeProfile: timeChangeProfile
})

export const keywordSearchInHeader = keywordInHeader => {
  // console.log("action: ", keywordInHeader);
  return {
    type: 'KEYWORD_SEARCH_IN_HEADER',
    keywordInHeader: keywordInHeader
  };
};